<template>
    <v-container>
        <form slot="leftContainer" autocomplete="nope" data-vv-scope="form-2" @submit.prevent="addInventary('form-2')">
            <v-card>
                <v-card-title class="grey lighten-4">
                    <b>Inventario</b>
                </v-card-title>
                <v-card-text class="pa-4">
                    <v-row dense align="center">
                        <v-col cols="12" sm="3">
                            <v-menu dense v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field dense v-model="form.operation_date" label="Fecha de Operación"
                                        prepend-icon="mdi-calendar" readonly data-vv-name="form.operation_date"
                                        data-vv-as="fecha inventary" :error-messages="errors.collect('form.operation_date')"
                                        v-on="on" />
                                </template>

                                <v-date-picker dense v-model="form.operation_date" no-title locale="es"
                                    @input="menu2 = false" />
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-autocomplete v-validate="'required'" dense v-model="form.types" :items="listTypes"
                                item-text="text" placeholder="INN" item-value="id"
                                :error-messages="errors.collect('form.types')" label="Motivo" data-vv-name="form.types"
                                data-vv-as="Tipo" filled rounded>
                            </v-autocomplete>

                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-autocomplete v-validate="'required'" dense v-model="form.warehouse_id"
                                :items="custom_warehouses" item-text="description" item-value="id"
                                :error-messages="errors.collect('form.warehouse_id')" label="Almacén Destino"
                                data-vv-name="form.warehouse_id" data-vv-as="Tipo" filled rounded>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field dense v-model="form.numGuide" data-vv-name="form.numGuide"
                                :error-messages="errors.collect('form.numGuide')" label="Vale/Guia" />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field dense v-model="form.oT" data-vv-name="form.oT"
                                :error-messages="errors.collect('form.oT')" label="Orden Trabajo" />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field dense v-model="form.numTicket" data-vv-name="form.numTicket"
                                :error-messages="errors.collect('form.numTicket')" label="Ticket" />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field dense v-model="form.numArea" data-vv-name="form.numArea"
                                :error-messages="errors.collect('form.numArea')" label="Area" />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field dense v-model="form.project" data-vv-name="form.project"
                                :error-messages="errors.collect('form.project')" label="Proyecto" />
                        </v-col>

                        <v-col cols="12" sm="3">
                            <v-autocomplete dense v-model="form.nameAuthorized"  :items="listaUser"
                                item-text="nombres" item-value="nombres"
                                 label="Authorizado"
                                />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-autocomplete dense v-model="form.nameReception"  :items="listaUser"
                                item-text="nombres" item-value="nombres"
                                :error-messages="errors.collect('form.nameReception')" label="Empleado Recibe"
                                 />
                        </v-col>

                        <v-col cols="12" sm="3">
                            <v-autocomplete v-validate="'required'" dense v-model="form.categories" :items="listCategories"
                                item-text="text" placeholder="INN" item-value="id"
                                :error-messages="errors.collect('form.categories')" label="Categoria"
                                data-vv-name="form.categories" data-vv-as="Tipo" filled rounded>
                            </v-autocomplete>

                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-textarea v-model="form.observations" data-vv-name="form.observations"
                                :error-messages="errors.collect('form.observations')" label="Observaciones" />
                        </v-col>

                        <!-- <v-col cols="12" sm="2">
                            <v-text-field
                                dense
                                data-vv-name="form.tipoCambio"
                                data-vv-as="form.tipoCambio"
                                v-model="form.tipoCambio"
                                label="Tipo Cambio"
                                @keypress="isNumber"
                            />
                        </v-col> -->
                        <SelectProductos items="true"></SelectProductos>
                    </v-row>


                    <v-col cols="12" sm="6" class="mt-2 align-right">
                        <v-btn type="submit" large :loading="loading" dark block>
                            <b>{{ 'AGREGAR TRANSACCIÓN' }}</b>
                        </v-btn>
                    </v-col>
                </v-card-text>
            </v-card>
        </form>
        <v-card-title class="grey lighten-4 mt-5">
            <v-row dense>
                <v-col cols="12" sm="5"> <b>LISTA DE STOCK</b> | <a href="/logistica/inventary/list">Ver Transacciones
                        <v-icon>mdi-link</v-icon>
                    </a>
                </v-col>
                <v-col cols="12" sm="1">
                </v-col>
                <v-col cols="12" sm="3"> <v-text-field dense v-model="search" append-icon="mdi-magnify" label="BUSCAR"
                        single-line hide-details />

                </v-col>

                <v-col cols="12" sm="3">
                    <v-btn class="mx-1" small color="primary" dark block @click="downloadHandle">
                        DESCARGAR <v-icon right dark> mdi-arrow-down-box </v-icon>
                    </v-btn>
                </v-col>
            </v-row>

        </v-card-title>
        <v-card-text class="white pa-2">
            <v-row dense>
                <v-col cols="12">
                    <v-data-table multi-sort :search="search" :headers="headers" :items="_listInventory" :loading="loading2"
                        class="elevation-1" :items-per-page="20">

                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>

    </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import DefaultForm from '@/utils/defaultForm'
import { exportXLSX } from '@/utils/genericUtils'
import SelectProductos from '@/components/selects/SelectProductInventary'

export default {
    components: {
        SelectProductos,
    },
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {

            menu2: false,
            loading: false,
            search: null,
            form: DefaultForm.formInventary(),
            openModalProveedor: false,
            loading2: false,
            listTypes: [
                { id: 'INN', text: 'ENTRADA' },
                { id: 'OUT', text: 'SALIDA' }

            ],
            listCategories: [
                { id: 'VENTA', text: 'VENTA' },
                { id: 'INSUMO', text: 'INSUMO' },
                { id: 'VENTA/INSUMO', text: 'VENTA/INSUMO' },


            ],
            headers: [
                { text: 'CÓD Interno', value: 'internal_id' },
                { text: 'Producto', value: 'description' },

                { text: 'Ubicacion', value: 'warehouse' },
                { text: 'CANT', value: 'stock' },
                { text: 'MONEDA', value: 'currency_type_id' },

                { text: 'PRECIO VENTA', value: 'sale_unit_price' },
                { text: 'CATEGORIA', value: 'category' },

            ]
        }
    },
    computed: {

        ...mapState('users', ['user', 'listaUser']),
        ...mapState('sedes', ['custom_warehouses', 'departamentos', 'provincias', 'distritos']),
        ...mapState('inventario', ['listInventory', 'inventory']),
        _listInventory() {
            return this.listInventory.map(x => {
                console.log('this.custom_warehouses', this.custom_warehouses)
                return {
                    ...x, warehouse: this.custom_warehouses.find(y => y.id == x.warehouse_id)?.description || ''
                }
            })
        }
    },
    methods: {
        ...mapActions({
            getCustomWarehouses: 'sedes/getCustomWarehouses'
        }),

        ...mapActions({
            getItemWarehouses: 'inventario/getItemWarehouses',
            getItemInventory: 'inventario/getItemInventory'

        }),
        ...mapMutations({
            /*             RESET_FORM: 'clientes/RESET_FORM',
            UPDATE_DATOS: 'clientes/UPDATE_DATOS', */
            OPEN_MODAL: 'mainUI/OPEN_MODAL',
            OPEN_BAR: 'mainUI/OPEN_BAR'
        }),
        async addInventary(name) {
            console.log('this.$validator', this.$validator)
            this.$validator
                .validateAll(name)
                .then(async (result) => {
                    console.log('result', result)
                    let text = 'Agregando Inventario'
                    if (result && this.inventory.length>0) {
                        console.log("this.inventory", this.inventory)

                        try {
                            this.loading = true
                            this.$store.commit('mainUI/OPEN_MODAL', {
                                state: true,
                                text
                            })
                            const data = {
                                types: this.form.types,
                                operation_date: this.form.operation_date,
                                warehouse_id: this.form.warehouse_id,
                                numGuide: this.form.numGuide,
                                numTicket: this.form.numTicket,
                                observations: this.form.observations,
                                categories: this.form.categories,
                                numArea: this.form.numArea,
                                project: this.form.project,
                                nameAuthorized: this.form.nameAuthorized,
                                nameReception: this.form.nameReception,
                                oT: this.form.oT,
                                products: JSON.stringify(this.inventory.map(x => {
                                    return {
                                        id: x.id, internal_id: x.internal_id, quantity: x.quantity, name: x.name
                                    }
                                })),

                            }
                            console.log('datatransaction', data)

                            const result = await this.$store.dispatch('inventario/postItemAddInventory', data)
                            console.log("postItemAddInventory", result)
                            this.loading = false
                            if (result.status == 200) {
                                this.form = DefaultForm.formInventary()
                                this.$store.commit('inventario/UPDATE_LISTA_INVENTORY', [])
                                //console.log('UPDATE_PRODUCTOS ', this.productos)

                                this.loading2 = true
                                await this.getItemInventory()
                                this.loading2 = false
                            } else {
                                this.$store.commit('mainUI/OPEN_BAR', {
                                    state: true,
                                    text: "Hay Errores de Stock. !revisar",
                                    color: 'pink'
                                })
                            }
                            this.$store.commit('mainUI/OPEN_MODAL', {
                                state: false,
                                text
                            })

                        } catch (error) {
                            console.log('err-> ', error)
                            text = 'Ha ocurrido un Error'
                        }
                    } else {
                        this.$store.commit('mainUI/OPEN_BAR', {
                            state: true,
                            text: "Hay Errores",
                            color: 'pink'
                        })
                    }
                    this.$store.commit('mainUI/OPEN_MODAL', {
                        state: false
                    })
                })
                .catch((e) => {
                    //console.log('Eerr ', e)
                    this.loading = false
                })
        },

        downloadHandle() {
            this.loading = true
            const headers = [
                'item_id',
                'internal_id',
                'description',
                'warehouse',
                'stock',
                'currency_type_id',
                'sale_unit_price',
                'category',
            ]
            const filename = `REPORT_INVENTARY_${Date.now()}`

            exportXLSX({ headers, campos: headers, arrayData: this._listInventory }, { filename })
            this.loading = false
        },
    },

    async created() {
        console.log('warehousesssss', this.custom_warehouses)
        await this.getCustomWarehouses()
        this.loading2 = true;

        // Ejecutar ambas promesas en paralelo
        await Promise.all([
            this.getItemWarehouses(),
            this.getItemInventory()
        ]);

        this.loading2 = false;
    }
}
</script>
